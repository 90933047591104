import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});


	// GSAP scrollreveal
	// Intro USP list
	gsap.from(".home-intro li", {
		scrollTrigger: {
			trigger: ".home-intro ",
			start: "20% 80%",
		},
		"opacity": "0", duration: 1.5, stagger: .65}		
	);

	gsap.from(".accordion-item", {
		scrollTrigger: {
			trigger: ".accordion ",
			start: "20% 80%",
		},
		"opacity": "0", "y":"30", duration: 1.5, stagger: .35}		
	);	

	gsap.from(".post-entry", {
		scrollTrigger: {
			trigger: ".post-entry ",
			start: "20% 80%",
		},
		"opacity": "0", "y":"20", duration: 1.5, stagger: .35}		
	);	

	// gsap.from(".galerij-item", {
	// 	scrollTrigger: {
	// 		trigger: ".galerij-item",
	// 		start: "20% 80%",
	// 	},
	// 	"opacity": "0", "y":"20", duration: 1.5, stagger: .35}		
	// );	




	// Realisaties filters
	function realisatiesFilters(){
		$('.realisaties-filters a').on('click', function(e){

			var hash = $(this).attr('href');
			hash = hash.replace(/^#/, '');

			$('.realisaties-filters a').each(function(){
				$(this).removeClass('active');
			});

			$(e.target).addClass('active');

			$('.post-entry').each(function(index){
				if($(this).hasClass(hash)){
					$(this).addClass('reveal');
				}else{
					$(this).removeClass('reveal');
				}
			});

		});		
	}

	realisatiesFilters();


	// LightBox gallery realisaties
	lightGallery(document.getElementById('galerijWrap'), {
		thumbnail: true,
	});
	

	// Heroicons SVGs WITHOUT Tailwind classes
	const speakerWaveIcon = `
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
		<path stroke-linecap="round" stroke-linejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
		</svg>
	`;
	const speakerXMarkIcon = `
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
		<path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
		</svg>
	`;
	const playIcon = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path stroke-linecap="round" stroke-linejoin="round"
				d="M5.25 5.404v13.192c0 .69.74
				1.138 1.345.832l12.227-6.596a.958.958 0
				000-1.664L6.595 4.572A.937.937 0 005.25
				5.404z" />
		</svg>
	`;
	const pauseIcon = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path stroke-linecap="round" stroke-linejoin="round"
				d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
		</svg>
	`;

	const video = document.getElementById("headerVideo");
	const muteBtn = document.getElementById("muteBtn");
	const playBtn = document.getElementById("playBtn");

	// Set initial icons (video is muted + autoplaying)
	muteBtn.innerHTML = speakerXMarkIcon; // "Muted" icon
	playBtn.innerHTML = pauseIcon;        // "Pause" icon

	// Mute/Unmute toggle
	muteBtn.addEventListener("click", function() {
		if (video.muted) {
		video.muted = false;
		muteBtn.innerHTML = speakerWaveIcon; // Unmuted icon
		} else {
		video.muted = true;
		muteBtn.innerHTML = speakerXMarkIcon; // Muted icon
		}
	});

	// Play/Pause toggle
	playBtn.addEventListener("click", function() {
		if (video.paused) {
		video.play();
		playBtn.innerHTML = pauseIcon; // Now playing -> show Pause icon
		} else {
		video.pause();
		playBtn.innerHTML = playIcon;  // Now paused -> show Play icon
		}
	});


});